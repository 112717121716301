export const useDataStore = defineStore("data", () => {
  const getDistinctValues = async (model: string, field: string, queryParameters?: Record<string, any>): Promise<Array<string>> => {
    const query = new URLSearchParams()
    if (queryParameters) {
      for (const [key, value] of Object.entries(queryParameters)) {
        if (value) query.append(key, value)
      }
    }

    const result = await useSamApi<Array<string>>(`/data/${model}/${field}/distinct?${query}`)
    return result.data
  }

  const getLookupValues = async (
    model: string,
    field: string,
    queryParameters?: Record<string, any>
  ): Promise<Array<{ _id: string; [key: string]: string }>> => {
    const query = new URLSearchParams()
    if (queryParameters) {
      for (const [key, value] of Object.entries(queryParameters)) {
        if (value) {
          query.append(key, Array.isArray(value) ? value.join() : value)
        }
      }
    }

    const result = await useSamApi<Array<{ _id: string; [key: string]: string }>>(`/data/${model}/${field}/lookup?${query}`)
    return result.data
  }

  return {
    getDistinctValues,
    getLookupValues,
  }
})
